import React, { useState } from "react";
import { graphql } from "gatsby";
import { Waypoint } from "react-waypoint";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import CountUp from "react-countup";
import Collapsible from "react-collapsible";
import {
  Subtitle,
  StyledA,
  StyledLink,
  StyledEmail,
  InnerWrapper,
  darkBlue,
  mainBlue,
  lightBlue,
  lightGrey,
  mainWhite,
  screen,
  innerWidth,
} from "../components/common/variables";
import { FaDownload, FaChevronRight, FaChevronDown } from "react-icons/fa";
import digitBg from "../images/digit-bg.png";

const Wrapper = styled.div`
  padding-top: 0;
  background-color: ${mainWhite};
  @media ${screen.medium} {
    padding-top: 70px;
  }

  .large-banner {
    display: none;
    @media ${screen.small} {
      display: block;
    }
  }

  .small-banner {
    display: block;
    @media ${screen.small} {
      display: none;
    }
  }

  .inner-wrapper {
    margin-top: 30px;
    @media ${screen.medium} {
      margin-top: 90px;
    }
  }

  .title {
    margin-bottom: 5px;
    @media ${screen.small} {
      margin-bottom: 15px;
    }
    @media ${screen.large} {
      margin-bottom: 20px;
    }
  }

  .subtitle-david {
    margin-bottom: 20px;
    display: none;
    @media ${screen.small} {
      display: block;
    }

    &--mobile {
      display: block;
      margin-bottom: 14px;
      @media ${screen.small} {
        display: none;
      }
    }
  }

  .main-container {
    &__content {
      max-width: 674px;
      color: ${darkBlue};
      h3,
      h4,
      h5,
      h6 {
        font-size: 1.18rem;
        font-weight: 400;
        margin-bottom: 0;
        @media ${screen.large} {
          font-size: 1.3rem;
          margin-bottom: 30px;
        }
      }
      p {
        font-size: 1rem;
        margin-bottom: 14px;
        @media ${screen.large} {
          font-size: 1.1rem;
          margin-bottom: 36px;
        }
      }

      strong {
        font-size: 1.18rem;
        @media ${screen.large} {
          font-size: 1.3rem;
          margin-bottom: 30px;
        }
      }
    }
  }

  .david-container {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    @media ${screen.small} {
      justify-content: space-between;
      flex-direction: row;
      margin-top: 90px;
    }

    &__img {
      max-width: 332px;
      margin: 0 auto;
      width: 100%;
      @media ${screen.medium} {
        max-width: 370px;
        margin: 0;
      }
      @media ${screen.large} {
        max-width: 462px;
      }
    }

    &__content {
      max-width: 674px;
      color: ${darkBlue};
      margin-left: 0;
      margin-top: 14px;
      @media ${screen.small} {
        margin-left: 35px;
        margin-top: 0;
      }
      h3,
      h4,
      h5,
      h6 {
        font-size: 1.2rem;
        font-weight: 400;
        margin-bottom: 40px;
      }
      p {
        font-size: 1rem;
        margin-bottom: 14px;
        @media ${screen.large} {
          font-size: 1.1rem;
          margin-bottom: 36px;
        }
      }
    }

    a {
      width: 100%;
      text-align: center;
      @media ${screen.xSmall} {
        width: auto;
      }
    }
  }

  .awards-container {
    margin-top: 30px;
    @media ${screen.small} {
      margin-top: 90px;
    }

    &__title {
      margin-bottom: 5px;
      @media ${screen.small} {
        margin-bottom: 20px;
      }
    }
    &__content {
      max-width: 674px;
      color: ${darkBlue};
      h3,
      h4,
      h5,
      h6 {
        font-size: 1.2rem;
        font-weight: 400;
        margin-bottom: 40px;
      }
      p {
        font-size: 1rem;
        margin-bottom: 14px;
        @media ${screen.large} {
          font-size: 1.1rem;
          margin-bottom: 36px;
        }
        &:last-child {
          margin-bottom: 30px;
          @media ${screen.medium} {
            margin-bottom: 50px;
          }
        }
      }
    }

    &__list {
      display: none;
      @media ${screen.small} {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        color: ${darkBlue};
      }

      li {
        max-width: 354px;
        margin-bottom: 50px;
        @media ${screen.medium} {
          max-width: 500px;
        }
        @media ${screen.large} {
          max-width: 536px;
        }
        h4 {
          font-size: 1.1rem;
          margin-bottom: 8px;
          font-weight: 900;
        }
        p {
          font-size: 1.1rem;
        }
      }

      &--mobile {
        @media ${screen.small} {
          display: none;
        }

        .Collapsible {
          border-top: 2px ${lightGrey} solid;
          position: relative;
          padding: 12px 0 12px 0;

          h3 {
            color: ${darkBlue};
            max-width: 261px;
            cursor: pointer;
            svg {
              color: ${darkBlue};
              position: absolute;
              right: 10px;
              top: 16px;
              font-size: 1.1rem;
            }
          }

          &__contentInner {
            p {
              color: ${darkBlue};
              font-size: 1rem;
              margin-top: 5px;
              padding-right: 20px;
            }
          }
        }
        div:last-child .Collapsible {
          border-bottom: 2px ${lightGrey} solid;
        }
      }
    }
  }

  .advising-container {
    padding-bottom: 10px;
    margin-top: 30px;
    @media ${screen.medium} {
      padding-bottom: 60px;
    }

    &__title {
      margin-bottom: 5px;
      @media ${screen.small} {
        margin-bottom: 20px;
      }
    }

    &__content {
      max-width: 674px;
      font-size: 1.1rem;
      color: ${darkBlue};
    }

    &__digits-container {
      border: 2px transparent solid;
      height: auto;
      max-width: ${innerWidth};
      padding: 0 33px;
      margin: 0 auto 30px auto;
      @media ${screen.small} {
        padding: 0 16px;
      }
      @media ${screen.medium} {
        background-image: url(${digitBg});
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto 100%;
        height: 600px;
        margin-top: 45px;
        padding: 0 60px;
      }
    }

    &__digit-list {
      color: ${mainBlue};
      text-align: center;
      margin-top: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @media ${screen.medium} {
        flex-wrap: nowrap;
        justify-content: space-between;
        margin-top: 116px;
      }

      h5 {
        color: ${darkBlue};
        font-size: 4rem;
        line-height: 1.3;
        @media ${screen.medium} {
          font-size: 3.9rem;
          color: ${mainBlue};
          line-height: 1.5;
        }
        @media ${screen.large} {
          font-size: 5rem;
        }
      }

      p {
        color: ${darkBlue};
        font-size: 1.05rem;
        font-variant-numeric: tabular-nums;
        @media ${screen.medium} {
          font-size: 1.15rem;
          color: ${mainBlue};
        }
      }
    }

    &__each-digit {
      background: ${lightGrey};
      padding: 5px 0 15px 0;
      width: 100%;
      margin: 10px 0;
      @media ${screen.small} {
        width: 47%;
        margin: 10px;
        padding: 10px;
      }
      @media ${screen.medium} {
        padding: 0;
        width: auto;
        background: none;
      }
    }
  }

  .contact-container {
    background-color: ${lightBlue};
    padding: 45px 0;
    @media ${screen.medium} {
      padding: 90px 320px 90px 0;
    }
    @media ${screen.large} {
      padding: 90px 0;
    }
    h6 {
      color: ${mainWhite};
      font-size: 1.3rem;
      @media ${screen.medium} {
        font-size: 2rem;
      }
    }
    p {
      color: ${mainWhite};
      margin-top: 20px;
      font-size: 1.1rem;
      font-weight: 300;
      @media ${screen.medium} {
        font-size: 1.5rem;
        margin-top: 10px;
      }
    }
    a {
      display: block;
      margin-top: 20px;
      margin-right: 15px;
      width: 100%;
      text-align: center;
      @media ${screen.xSmall} {
        display: inline-block;
        width: auto;
      }
      &:hover {
        background: ${mainWhite};
        color: ${lightBlue};
      }
    }
  }
`;

const AboutPage = ({ data }) => {
  const titleTag = data.content.data.title_tag;
  const metaDescription = data.content.data.meta_description;
  const mainContent = data.content.data.main_description.html;
  const davidContent = data.content.data.description.html;
  const awardsContent = data.content.data.awards_description.html;
  const awardsListText = data.content.data.list_information;
  const advisingText = data.content.data.description1;
  const davidImg = data.content.data.image.gatsbyImageData;
  const pdfFile = data.general.data.biography_file.url;
  const email = data.general.data.email_address;
  const countriesNum = data.content.data.countries_worked_in;
  const companiesNum = data.content.data.companies_engaged_with;
  const eventsNum = data.content.data.events_spoken_at;
  const advisersNum = data.content.data.advisers_presented_to;

  // Start counting the digit when set to true.
  const [counter, setCounter] = useState(false);

  // This function will be called by Waypoint scroll.
  const runCounter = () => {
    setCounter(true);
  };

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} />
      <Wrapper>
        <StaticImage
          className="large-banner"
          src="../images/global-adviser-alpha-helps-transform-advice-businesses-into-world-class-firms.jpg"
          alt="Transform advice businesses into world class firms"
          placeholder="blurred"
        />
        <StaticImage
          className="small-banner"
          src="../images/global-adviser-alpha-help-transform-advice-businesses-into-world-class-firms.jpg"
          alt="Transform advice businesses into world class firms"
          placeholder="blurred"
        />
        <InnerWrapper className="inner-wrapper">
          <Subtitle className="title" dark>
            About us
          </Subtitle>
          <main className="main-container">
            <div
              className="main-container__content"
              dangerouslySetInnerHTML={{ __html: mainContent }}
            />
          </main>

          <div className="david-container">
            <div className="david-container__img">
              <Subtitle className="subtitle-david--mobile" dark>
                About David
              </Subtitle>
              <GatsbyImage
                image={davidImg}
                alt="Work directly with David Haintz"
              />
            </div>
            <div className="david-container__content">
              <Subtitle className="subtitle-david" dark>
                About David
              </Subtitle>

              <div dangerouslySetInnerHTML={{ __html: davidContent }} />
              <StyledA href={pdfFile} target="_blank" download updown>
                DAVID'S BIO <FaDownload />
              </StyledA>
            </div>
          </div>
          <div className="awards-container">
            <Subtitle className="awards-container__title" dark>
              Awards & achievements
            </Subtitle>
            <div
              className="awards-container__content"
              dangerouslySetInnerHTML={{ __html: awardsContent }}
            />
            <ul className="awards-container__list">
              {awardsListText.map((list, i) => (
                <li key={list.title1.text}>
                  <h4>{list.title1.text}</h4>
                  <p>{list.description1}</p>
                </li>
              ))}
            </ul>
            <ul className="awards-container__list--mobile">
              {awardsListText.map((list, i) => (
                <li key={list.title1.text}>
                  <Collapsible
                    triggerWhenOpen={
                      <div>
                        <h3>
                          {list.shorter_title}
                          <FaChevronDown />
                        </h3>
                      </div>
                    }
                    trigger={
                      <div>
                        <h3>
                          {list.shorter_title}
                          <FaChevronRight />
                        </h3>
                      </div>
                    }
                    transitionTime={150}
                  >
                    <p className="description">{list.description1}</p>
                  </Collapsible>
                </li>
              ))}
            </ul>
          </div>
          <div className="advising-container">
            <Subtitle className="advising-container__title" dark>
              International advising
            </Subtitle>

            <p className="advising-container__content">{advisingText}</p>
          </div>
        </InnerWrapper>
        <div className="advising-container__digits-container">
          <Waypoint onEnter={runCounter}>
            <div className="advising-container__digit-list">
              <div className="advising-container__each-digit">
                <h5>
                  {counter && <CountUp duration={2} end={countriesNum} />}
                </h5>
                <p>Countries worked in</p>
              </div>
              <div className="advising-container__each-digit">
                <h5>
                  {counter && <CountUp duration={2} end={companiesNum} />}
                </h5>
                <p>Companies engaged with</p>
              </div>
              <div className="advising-container__each-digit">
                <h5>{counter && <CountUp duration={2} end={eventsNum} />}</h5>
                <p>Events spoken at</p>
              </div>
              <div className="advising-container__each-digit">
                <h5>
                  {counter && (
                    <CountUp duration={2.6} end={advisersNum} separator="," />
                  )}
                  +
                </h5>
                <p>Advisers presented to</p>
              </div>
            </div>
          </Waypoint>
        </div>
        <div className="contact-container">
          <InnerWrapper>
            <h6>
              Are you wanting to know how to attract and retain the right
              clients and talent?
            </h6>
            <p>
              If this sounds like you, sign up for our Client-Centric Advice
              Program or get in contact.
            </p>
            <StyledLink to="/programs" normal="true">
              PROGRAM DETAILS <FaChevronRight />
            </StyledLink>
            <StyledEmail
              obfuscateChildren={false}
              style={{ border: "1.6px #fff solid", boxSizing: "border-box" }}
              email={email}
              headers={{
                subject:
                  "I want to know how to attract and retain the right clients and talent",
              }}
            >
              CONTACT <FaChevronRight />
            </StyledEmail>
          </InnerWrapper>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default AboutPage;

export const dataQuery = graphql`
  {
    content: prismicAboutPage {
      data {
        title_tag
        meta_description
        main_description {
          html
        }
        description {
          html
        }
        awards_description {
          html
        }
        list_information {
          title1 {
            text
          }
          shorter_title
          description1
        }
        description1
        image {
          alt
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        countries_worked_in
        companies_engaged_with
        events_spoken_at
        advisers_presented_to
      }
    }
    general: prismicGeneralDetails {
      data {
        biography_file {
          url
        }
        email_address
      }
    }
  }
`;
